// @ts-nocheck
'use client';
import Image from 'next/image';
import AppleLogo from '../../../../assets/images/apple_logo.svg';
import huawei_logo from '../../../../assets/images/huawei_logo.svg';
import GoogleIcon from '../../../../assets/images/GoogleIcon.svg';
import FindAStore from '../../../../assets/images/FindAStore.svg';
import { Input, Button, IKLink } from '@/components/shared/material';
import { useCallback, useState, useEffect } from 'react';
import { submitForm } from '@/actions';
import { Form } from '@/types/types';
import Link from 'next/link';
import { getFullUrl } from '@/lib/pathUtils';
import toast from 'react-hot-toast';
import { InputField } from '@/components/shared/form';
import InputToolTip from '@/components/shared/form/InputToolTip';
import NewsletterEmailField from '@/components/shared/form/EmailField';
import { getForm } from '@/lib/hubspot/services/formService';
import NewsLetter from './NewsLetter';
interface FieldValidation {
  blockedEmailDomains: string[];
  useDefaultBlockList: boolean;
}

interface Field {
  objectTypeId: string;
  name: string;
  label: string;
  required: boolean;
  hidden: boolean;
  fieldType: string;
  validation: FieldValidation;
}

interface FieldGroup {
  groupType: string;
  richTextType: string;
}

// Example interface using the provided data

export default function MarketingCards(props: FieldGroup) {
  const cardTitle = 'Footer';

  return (
    <div className='relative z-10 -mt-8 mb-10 flex flex-row flex-wrap justify-between gap-6 md:flex-nowrap md:gap-4 xl:gap-2'>
      <div className='flex w-full flex-col flex-wrap items-start rounded-[10px] bg-charcoal-900 px-6 py-6 md:w-[28%] xl:w-[30%]'>
        <div>
          <p className='text-base text-yellow-50 md:text-sm/[12px] md:leading-6'>
            Download our award winning app from your app store
          </p>
        </div>
        <div className='mt-4 flex grid-cols-2 flex-wrap justify-between gap-4 md:justify-start'>
          <Link
            href='https://play.google.com/store/apps/details?id=ik.emerge.ikhokha'
            className='flex max-w-[120px] select-none rounded-md hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900'
          >
            <Image
              src={GoogleIcon}
              width={120}
              height={30}
              className='w-full select-none'
              alt='Google app logo'
            />
          </Link>
          <Link
            href={'https://appgallery.huawei.com/#/app/C102486477'}
            className='flex max-w-[120px] select-none rounded-md hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900'
          >
            <Image
              src={huawei_logo}
              width={120}
              height={30}
              className='w-full select-none'
              alt='huawei logo'
            />
          </Link>
          <Link
            href={'https://apps.apple.com/za/app/ikhokha/id1325073911'}
            className='flex w-full max-w-[120px] select-none rounded-md hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900'
          >
            <Image
              src={AppleLogo}
              width={110}
              height={30}
              className='w-full select-none'
              alt='Apple app logo'
            />
          </Link>
        </div>
      </div>

      <IKLink
        link={
          {
            url: getFullUrl('store-locator'),
            type: 'internal',
          } as any
        }
        className='flex w-full flex-wrap items-center gap-4 rounded-[10px] bg-charcoal-900 px-6 py-6 hover:ring-2 hover:ring-yellow-500 hover:ring-offset-2 hover:ring-offset-charcoal-900 md:w-[20%] md:gap-2 xl:w-[18%] xl:flex-nowrap xl:gap-4'
      >
        <div>
          <div>
            <Image src={FindAStore} width={30} height={30} alt='Find a Store' />
          </div>
        </div>
        <div className='text-base text-yellow-50 md:text-sm/[12px] md:leading-6'>
          <p>Store Locator</p>
          <p>Find a store near you</p>
        </div>
      </IKLink>

      <NewsLetter {...props} />
    </div>
  );
}
