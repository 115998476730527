'use client';

import IKLink from '@/components/shared/material/IKLink';
import Image from 'next/image';
import ikLogo from '../../../../assets/images/ik_logo_in_footer.svg';
import { MenuItemLink } from '@/types/types';

interface MenuItem {
  text: string | null;
  link: MenuItemLink;
  subTitle: string | null;
  subitems: MenuItem[] | null;
}

interface MenuSection {
  title: string;
  items: MenuItem[];
}

interface MenuInterface {
  items: MenuSection[];
}

export default function Categories({ items }: MenuInterface) {
  return (
    <div className='my-6 flex justify-between gap-4'>
      <div className='grid w-full grid-cols-2 justify-between gap-x-4 gap-y-12 lg:grid-cols-5 lg:gap-12'>
        {items?.map(({ title, items }) => (
          <div key={title}>
            <ul>
              <p className='mb-2 text-sm/[10px] font-extrabold tracking-[.15em] text-yellow-150 md:mb-4'>
                {title?.toUpperCase()}
              </p>

              {items.map(({ text, link, subTitle, subitems }, index) => (
                <li
                  key={`footer-categories-${index}`}
                  className='text-sm/[10px] leading-4 md:text-xs md:leading-6'
                >
                  <IKLink
                    link={link}
                    className='my-0.5 inline-block py-1.5 text-sm font-medium transition-colors hover:text-yellow-150 md:my-0'
                  >
                    {text}
                  </IKLink>

                  {subTitle && (
                    <p className='mb-4 mt-6 text-sm/[10px] font-extrabold tracking-[.15em] text-yellow-150'>
                      {subTitle.toUpperCase()}
                    </p>
                  )}

                  {subitems && (
                    <ul>
                      {subitems.map(({ text, link }, subIndex) => (
                        <li
                          key={`footer-categories-${index}-${subIndex}`}
                          className='text-sm/[10px] leading-4 md:text-xs md:leading-6'
                        >
                          <IKLink
                            link={link}
                            className='my-0.5 inline-block py-1.5 text-sm font-medium transition-colors hover:text-yellow-150 md:my-0'
                          >
                            {text}
                          </IKLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className='pr-10'>
          <Image src={ikLogo} width={110} height={30} alt='ik Logo' />
          <div className='mt-4 text-base font-medium md:text-sm md:leading-6'>
            <p>1 Nokwe Avenue,</p>
            <p>Umhlanga Ridge,</p>
            <p>4319</p>
          </div>
        </div>
      </div>
    </div>
  );
}
