'use client';
import Facebook from '../../../../assets/images/Facebook.svg';
import Instagram from '../../../../assets/images/Instagram.svg';
import LinkedIn from '../../../../assets/images/LinkedIn.svg';
import Twitter from '../../../../assets/images/X.svg';
import Youtube from '../../../../assets/images/Youtube.svg';
import IKLink from '@/components/shared/material/IKLink';
import Image from 'next/image';
import { Circle } from '@phosphor-icons/react';

import { MenuItemLink } from '@/types/types';
import Link from 'next/link';

interface LinkItem {
  item: {
    text: string;
    link: MenuItemLink;
  };
}

interface LinksData {
  links: LinkItem[];
}

export default function Copyright(props: LinksData) {
  const currentYear = new Date().getFullYear();

  const { links } = props;

  return (
    <div className='mt-2 flex w-full flex-col items-center justify-center border-t border-gray-300 py-4 md:flex-row md:justify-between'>
      <small className='mb-4 text-center font-bold text-gray-100 md:mb-0'>
        &copy; {currentYear} iKhokha All Rights Reserved.
      </small>
      <div>
        <ul className='mb-3 flex list-none flex-wrap justify-center gap-4 md:mb-0'>
          {links
            ? links.map((item, index) => (
                <li
                  key={`footer-copyright-${index}`}
                  className='flex items-center gap-4'
                >
                  <IKLink
                    link={item?.item.link}
                    className='py-0 text-sm font-medium text-gray-300 transition-colors hover:text-yellow-150 md:py-1.5'
                  >
                    {item?.item.text}
                  </IKLink>
                  {index !== links.length - 1 && (
                    <Circle className='h-1 w-1 text-gray-300' weight='fill' />
                  )}
                </li>
              ))
            : ''}
        </ul>
      </div>
      <div className='flex items-center gap-4 text-white sm:justify-center'>
        <Link href={`${process.env.NEXT_PUBLIC_FACEBOOK_URL}`}>
          <Image src={Facebook} width={30} height={30} alt='Facebook logo' />
        </Link>
        <Link href={`${process.env.NEXT_PUBLIC_INSTAGRAM_URL}`}>
          <Image src={Instagram} width={30} height={30} alt='Instagram logo' />
        </Link>
        <Link href={`${process.env.NEXT_PUBLIC_TWITTER_URL}`}>
          <Image src={Twitter} width={22} height={22} alt='x logo' />
        </Link>
        <Link href={`${process.env.NEXT_PUBLIC_YOUTUBE_URL}`}>
          <Image src={Youtube} width={30} height={30} alt='Youtube logo' />
        </Link>
        <Link href={`${process.env.NEXT_PUBLIC_LINKEDIN_URL}`}>
          <Image src={LinkedIn} width={30} height={30} alt='LinkedIn logo' />
        </Link>
      </div>
    </div>
  );
}
